<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column key="record" title="阶段" :ellipsis="true" width="49%">
      <template slot-scope="record">
        <a-row type="flex" align="middle" :gutter="24" style="flex-wrap: nowrap; overflow: hidden">
          <a-col flex="0 1 80px">
            <a-avatar style="width: 142px; height: 80px" shape="square" :src="record.url"></a-avatar>
          </a-col>

          <a-col
            flex="0 1 100%"
            style="
              height: 80px;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <a-row>
              <span class="heading-color" style="font-size: 14px">{{ record.name }}</span>
            </a-row>

            <div>
              <a-row type="flex" align="middle" style="flex-wrap: nowrap">
                <a-col>
                  分类:
                  <span class="primary-color" style="margin-left: 8px">
                    {{ record.mainFieldName }} > {{ record.fieldName }}
                  </span>
                </a-col>
                <a-col>
                  <a-divider type="vertical"></a-divider>
                </a-col>
                <a-col>
                  创建人:<span style="margin-left: 8px">{{ record.creator }}</span>
                </a-col>
                <a-col>
                  <a-divider type="vertical"></a-divider>
                </a-col>
                <a-col>
                  回合数:<span style="margin-left: 8px">{{ record.minimumRounds }}</span>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="margin-top: 8px">
                <a-col flex="1" class="desc">
                  简介: <span style="margin-left: 8px">{{ record.introduction }}</span>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </template>
    </a-table-column>

    <a-table-column key="status" title="状态" align="center" :ellipsis="true" width="17%">
      <template slot-scope="record">
        <a-button
          v-if="$auth('phases_update_status')"
          type="primary"
          :class="record.status === 'AVAILABLE' ? 'success-btn' : 'error-btn'"
          shape="round"
          @click="handleStatus(record)"
        >
          {{ record.status | StatusText() }}
        </a-button>
      </template>
    </a-table-column>

    <a-table-column
      key="createTimestamp"
      title="添加时间"
      data-index="createTimestamp"
      align="center"
      :ellipsis="true"
      width="17%"
    >
      <template slot-scope="createTimestamp">
        <span style="font-size: 14px">
          {{ createTimestamp | moment('YYYY-MM-DD') }}
        </span>
      </template>
    </a-table-column>

    <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="17%">
      <template slot-scope="record">
        <a-row :gutter="[0, 4]">
          <a-col v-if="$auth('phases_select')">
            <a-button type="link" size="small" @click="handleDetail(record)">
              <a-icon type="eye" /><span>查看</span>
            </a-button>
          </a-col>
          <a-col v-if="$auth('phases_update')">
            <a-button type="link" size="small" @click="handleEdit(record)">
              <a-icon type="edit" /><span>编辑</span>
            </a-button>
          </a-col>
          <a-col v-if="$auth('phases_delete')">
            <a-button type="link" size="small" style="color: #f5222d" @click="handleRemove(record)">
              <a-icon type="delete" /><span>删除</span>
            </a-button>
          </a-col>
        </a-row>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as phaseApi from '@/api/phase'
export default {
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: [],
    }
  },
  activated() {
    const { ready, getData } = this

    if (ready) {
      getData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query,
      } = this

      this.listLoading = true

      return phaseApi
        .list({ ...query, pageIndex, pageSize })
        .then((res) => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: (total) => {
          const h = this.$createElement
          const {
            pagination: { pageSize },
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据'),
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    handleEdit(record) {
      this.$router.push({
        path: '/skill-management/phase/edit/' + record.id,
      })
    },
    handleDetail(record) {
      this.$router.push({
        path: '/skill-management/phase/' + record.id,
      })
    },
    handleRemove(record) {
      const { $confirm, $notification, $info, getData } = this

      const confirm = $confirm({
        title: '确定删除',
        content: '确定删除阶段',
        onOk: () => {
          return phaseApi
            .remove(record.id)
            .then(() => {
              $notification['success']({ message: '操作成功' })
              getData()
            })
            .catch((err) => {
              confirm.destroy()
              $info({
                title: '不可删除',
                content: err.msg,
              })
            })
        },
      })
    },
    handleStatus(record) {
      const { $confirm, $notification, $info, getData } = this
      const { id, status } = record

      const nextStatusMap = {
        AVAILABLE: {
          title: '确定禁用',
          content: '确定禁用当前阶段',
        },
        UNAVAILABLE: {
          title: '确定启用',
          content: '确定启用当前阶段',
        },
      }
      const nextStatus = nextStatusMap[status]

      const confirm = $confirm({
        ...nextStatus,
        onOk: () => {
          return phaseApi
            .updateStatus(id)
            .then((res) => {
              // console.log(res)
              const { code, msg } = res
              if (code === 0) {
                $notification['success']({ message: '操作成功' })
                getData()
              }
              if (code === 9012) {
                $info({
                  title: '操作失败',
                  content: msg,
                })
              }
            })
            .catch((err) => {
              console.log(err)
              confirm.destroy()
            })
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 24px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
